<script>
import InternalPagesLayout from "@/layouts/InternalPagesLayout.vue";
import Empty from "@/components/Empty.vue";
import ImperativeDialog from "@/components/ImperativeDialog.vue";

import { apiGetGames, apiCheerUp } from "@/http";
import { mixinableInfiniteLoad, mixinablePagination } from "@/mixins";

import imageAssist from "@/assets/images/encourage/assist.png";
import imageAssisted from "@/assets/images/encourage/assisted.png";
import imageEliminated from "@/assets/images/encourage/eliminated.png";
import iconFire from "@/assets/images/icon_fire.png";
import iconNumberOne from "@/assets/images/icon_number_one.png";

// /ann_api/h5/ranking/total?page=1

export default {
  mixins: [mixinableInfiniteLoad, mixinablePagination],
  components: {
    InternalPagesLayout,
    Empty,
    ImperativeDialog,
  },
  data() {
    return {
      tab: "bet",
      assistedTeams: [],
      games: {},
      assistErrorMessage: "",
      assistTarget: "",

      imageAssist,
      imageAssisted,
      imageEliminated,
      iconFire,
      iconNumberOne,

      // mixin
      infiniteLoadApi: "/ann_api/h5/ranking/total",
      infiniteLoadTriggerDistance: 126,

      // mixin
      pagination: {
        size: 50,
      },
    };
  },
  methods: {
    getGames() {
      apiGetGames().then((res) => {
        res.data.map((game) => {
          this.games[game.id] = game;
        });
      });
    },

    refresh(done) {
      this.pagination.page = 0;
      this.pagination.total = 0;
      this.pagination.items = [];

      this.$nextTick(() => {
        this.infiniteLoadReload()?.then(() => {
          done();
        });
      });
    },

    handleActive(id) {
      this.tab = id;

      if (id === "bet") {
        this.infiniteLoadApi = "/ann_api/h5/ranking/total";
      } else {
        this.infiniteLoadApi = "/ann_api/h5/team/getTeam";
      }

      this.pagination.page = 0;
      this.pagination.total = 0;
      this.pagination.items = [];

      this.$nextTick(() => {
        this.infiniteLoadReload();
      });
    },

    handleAssist(item) {
      if (this.assistedTeams.includes(item.id) || item.status === 1) {
        return;
      }

      this.assistTarget = item.name;

      this.dialogConfirmAssist?.open((context) => {
        context.close();

        apiCheerUp({ team_id: item.id }).then((res) => {
          if (res.errorCode !== 1000) {
            this.assistErrorMessage = res.errorMessage;
            this.dialogRemainingCheerLimit?.open((context) => {
              context.close();
            });

            return;
          }

          if (res.data === -1) {
            this.assistErrorMessage = "今天助威次数不足哦～ 每天每人可助威4次！";
            this.dialogRemainingCheerLimit?.open((context) => {
              context.close();
            });
          } else if (res.data === 0) {
            this.dialogPointsIncrease10?.open((context) => {
              context.close();
            });
          } else {
            this.dialogPointsIncrease10?.open((context) => {
              context.close();
            });

            this.dialogPointsIncrease100?.open((context) => {
              context.close();
            });
          }
        });
      })

      
    },

    // mixin
    infiniteLoadProcess(res) {
      let items;
      let total;
      let page;
      let assisteds;

      if (this.tab === "bet") {
        items = res.data.data;
        total = res.data.total;
        page = res.data.current_page;
        assisteds = [];
      } else {
        items = res.data.data.data;
        total = res.data.data.total;
        page = res.data.data.current_page;
        assisteds = res.data.team.map((id) => Number.parseInt(id, 10));
      }

      this.pagination.items.push(...items);
      this.pagination.total = total;
      this.pagination.page = page;
      this.assistedTeams.push(...assisteds);
    },

    // mixin
    paginationChangeHandler() {
      console.log(this.pagination.page, this.pagination.size);
    },
  },
  computed: {
    // mixin
    infiniteLoadEnable() {
      if (
        this.pagination.items.length >= this.pagination.total &&
        this.pagination.items.length !== 0
      ) {
        return false;
      }

      return true;
    },

    // mixin
    infiniteLoadParams() {
      return {
        orderRow: 1,
        page: this.pagination.page + 1,
        page_er: this.pagination.size,
      };
    },

    dialogConfirmAssist(){
      return this.$refs.dialogConfirmAssist;
    },
    dialogRemainingCheerLimit() {
      return this.$refs.dialogRemainingCheerLimit;
    },
    dialogPointsIncrease10() {
      return this.$refs.dialogPointsIncrease10;
    },
    dialogPointsIncrease100() {
      return this.$refs.dialogPointsIncrease100;
    },
  },
  mounted() {
    this.getGames();
    this.infiniteLoadReload();
  },
};
</script>

<template>
  <InternalPagesLayout :refresh="refresh">
    <div class="page">
      <div class="tab">
        <!--                     -->
        <div
          :class="['tab_item', tab === 'bet' && 'tab_item__active']"
          @click="handleActive('bet')"
        >
          竞猜榜
          <div class="line" />
        </div>
        <div
          :class="['tab_item', tab === 'encourage' && 'tab_item__active']"
          @click="handleActive('encourage')"
        >
          威力榜
          <div class="line" />
        </div>
      </div>

      <div class="total">
        共计
        <span class="count">{{ pagination.total }} </span>
        个选手参与排名
      </div>

      <div class="items">
        <div
          class="item"
          v-for="(item, index) in pagination.items"
          :key="item.id"
        >
          <div class="sort">
            <span v-if="index !== 0">
              {{ index + 1 }}
            </span>
            <img :src="iconNumberOne" v-else style="width: 20px" />
          </div>

          <img
            class="picture"
            v-if="tab !== 'bet'"
            :src="games[item.type]?.img"
          />
          <img
            class="picture"
            v-else="tab !== 'bet'"
            :src="item.user.head_img"
          />

          <div class="name">
            <span v-if="tab === 'bet'">
              {{ item.user.name }}
            </span>
            <span v-else>
              {{ item.name }}
            </span>
          </div>

          <div class="count" :style="tab === 'bet' ? 'margin-left: auto' : ''">
            <img :src="iconFire" class="icon" />
            <span v-if="tab === 'bet'">
              {{ item.integrate }}
            </span>
            <span v-else>
              {{ item.sign_integrate }}
            </span>
          </div>

          <div class="actions" v-if="tab !== 'bet'">
            <div class="assist_button" @click="handleAssist(item)">
              <img v-if="item.status === 1" :src="imageEliminated" />
              <img v-else :src="assistedTeams.includes(item.id)
                ? imageAssisted
                : imageAssist
                " />
            </div>
          </div>
        </div>
      </div>

      <div class="loading" v-if="infiniteLoadPending">正在加载</div>

      <div
        class="nodata"
        v-if="!infiniteLoadPending && pagination.items.length === 0"
      >
        <Empty />
      </div>
    </div>

    <ImperativeDialog
      ref="dialogConfirmAssist"
      confirmLabel="确认"
    >
      <div class="dialog-body">
        <div class="message" style="margin-top: 10px">
          是否确认给【{{ assistTarget }}】助威？
        </div>
      </div>
    </ImperativeDialog>
    <ImperativeDialog
      ref="dialogRemainingCheerLimit"
      hideCancelButton
      confirmLabel="知道了"
    >
      <div class="dialog-body">
        <div class="message" style="margin-top: 10px">
          {{ assistErrorMessage }}
        </div>
      </div>
    </ImperativeDialog>
    <ImperativeDialog
      ref="dialogPointsIncrease10"
      confirmLabel="积分记录"
      cancelLabel="关闭"
    >
      <div class="dialog-body">
        <div class="title" style="margin-top: 10px">
          积分 <span class="hightlight">+10</span>
        </div>
        <div class="tips" style="margin-top: 25px">可到我的积分记录查询</div>
      </div>
    </ImperativeDialog>
    <ImperativeDialog
      ref="dialogPointsIncrease100"
      confirmLabel="积分记录"
      cancelLabel="关闭"
    >
      <div class="dialog-body">
        <div class="title" style="margin-top: 10px">
          积分 <span class="hightlight">+100</span>
        </div>
        <div class="message" style="margin-top: 10px">恭喜本周助威满20次</div>
        <div class="tips" style="margin-top: 10px">可到我的积分记录查询</div>
      </div>
    </ImperativeDialog>
  </InternalPagesLayout>
</template>

<style scoped lang="less">
.page {
  .tab {
    padding: 0px 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    margin: 14px;

    .tab_item {
      font-family: Tencent;
      color: rgba(171, 171, 171, 1);

      .line {
        width: 30px;
        height: 2px;
        border-radius: 2px 2px 2px 2px;
        background: rgb(240, 116, 91);
        margin: auto;
        margin-top: 4px;
        opacity: 0;
      }

      &__active {
        color: rgba(35, 35, 35, 1);

        .line {
          opacity: 1;
        }
      }
    }
  }

  .total {
    color: rgba(210, 210, 210, 1);
    font-size: 12px;
    text-align: left;
    padding: 0px 12px;
    box-sizing: border-box;
    margin: 16px 0px;

    .count {
      color: rgba(240, 116, 91, 1);
    }
  }

  .items {
    box-sizing: border-box;
    .item {
      background: #ffffff;
      padding: 12px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      height: 64px;

      &:nth-child(even) {
        background-color: #f8f9fc;
      }

      .sort {
        font-family: Tencent;
        color: #ff6948;
        font-size: 16px;
        width: 20px;
        text-align: center;
      }

      .picture {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        margin-left: 12px;
      }

      .name {
        margin-left: 16px;
      }

      .count {
        color: #f08a5b;
        margin-left: 20px;

        .icon {
          width: 12px;
        }
      }

      .actions {
        margin-left: auto;
        text-align: right;

        .assist_button {
          height: 24px;

          img {
            height: 100%;
          }
        }
      }
    }
  }

  .loading {
    color: #9ba2a5;
    line-height: 300px;
  }

  .nodata {
    margin-top: 40px;
  }
}

.dialog-body {
  display: flex;
  flex-direction: column;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;

  .message {
    color: #435057;
    font-weight: 400;
    font-size: 16px;
  }

  .title {
    font-size: 18px;
  }

  .hightlight {
    color: #ff6b49;
  }

  .tips {
    font-size: 13px;
    color: #b1b1b1;
  }
}
</style>
